import { RadioGroup } from "@headlessui/react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import Layout from "../components/Global/layout";
import * as Yup from "yup";
import axios from "axios";
import formErrors from "../components/Global/formErrors";
import { graphql, useStaticQuery } from "gatsby";
import SEO from "../components/seo/SEO";

const TestRides = () => {
  const [isSent, setIsSent] = useState(false);
  const data = useStaticQuery(graphql`
    {
      allPrismicTestRide {
        edges {
          node {
            data {
              page_title
              page_subtitle
              schedule_description
              test_ride_description
              schedule_title
              test_ride_schedule {
                address
                city
                ending_date(formatString: "Do MMM YY")
                starting_date(formatString: "Do MMM")
              }
            }
          }
        }
      }
    }
  `);

  const submitForm = (values) => {
    axios({
      headers: {
        "Content-Type": "application/json",
      },
      method: "post",
      url: "https://hook.integromat.com/dc7sxkvpg9w4w3srjlgcpnyqivi3vvi9",
      data: JSON.stringify(values),
    })
      .then((r) => {
        console.log("Thanks!", values);
      })
      .catch((r) => {
        console.log("Failed", values);
      });
  };

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .required("This Field is Required"),
    lastName: Yup.string()
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .required("This Field is Required"),
    eMail: Yup.string()
      .email("Please give your E-Mail")
      .required("Please fill your E-Mail Address"),
    mobileNumber: Yup.string().matches(/^[6-9]\d{9}$/, {
      message: "Please enter valid number.",
      excludeEmptyString: false,
    }).required("Mobile Number is Required"),
  });
  return (
    <>
      <SEO />
      <Layout>
        <section className="contact-options-grid mt-10">
          <div className="bg-gray-900 p-2"></div>
          <div className="flex flex-col space-y-3 md:space-y-4 bg-gray-900 p-8 md:p-12">
            <h1 className="text-2xl text-blue-500 font-semibold">
              {data.allPrismicTestRide.edges[0].node.data.page_title}
            </h1>

            <h2 className="text-4xl text-white">
              {data.allPrismicTestRide.edges[0].node.data.page_subtitle}
            </h2>
            <p className="text-base font-light leading-relaxed text-gray-400 md:pr-40 lg:pr-80">
              {data.allPrismicTestRide.edges[0].node.data.test_ride_description}
            </p>
          </div>
          <div className="bg-gray-900 p-2"></div>
          <div className="bg-gray-900 p-2"></div>
          <div className="flex flex-col space-y-3 md:space-y-4 bg-gray-900 p-8 md:p-12">
            <h3 className="text-2xl font-medium sm:text-3xl text-blue-500">
              {data.allPrismicTestRide.edges[0].node.data.schedule_title}
            </h3>
            <p className="text-red-500 font-regular text-lg">Test Rides have been temporarily Suspended, Register using the form below to get notified once it resumes. We will also be posting regular updates on our social media platform</p>
            {/* <p className="text-base font-light leading-relaxed text-gray-400 md:pr-40 lg:pr-80">
              {data.allPrismicTestRide.edges[0].node.data.schedule_description}
            </p> */}
            {/* <div className="grid grid-cols-2 gap-3">
              {data.allPrismicTestRide.edges[0].node.data.test_ride_schedule.map(
                (item) => (
                  <div className="flex flex-col space-y-3 py-3 col-span-2 md:col-span-1">
                    <ul className="flex flex-col space-y-1">
                      <h4 className="text-xl font-medium text-blue-300">
                        {item.city}
                      </h4>
                      <p className="text-base text-gray-400 font-light">
                        {item.address}
                      </p>
                    </ul>
                    <p className="text-lg text-gray-400 font-light">
                      <span className="font-medium text-gray-300">
                        {item.starting_date}
                      </span>{" "}
                      to{" "}
                      <span className="font-medium text-gray-300">
                        {item.ending_date}
                      </span>
                    </p>
                  </div>
                )
              )}
            </div> */}
          </div>
          <div className="bg-gray-900 p-2"></div>
          <div className="bg-gray-900 p-2"></div>
          <div className="bg-gray-900 p-2">
            {!isSent ? (
              <Formik
                initialValues={{
                  cityName: "",
                  firstName: "",
                  lastName: "",
                  eMail: "",
                  mobileNumber: "",
                }}
                onSubmit={(values, actions) => {
                  submitForm(values);
                  console.log(values);
                  actions.setSubmitting(false);
                  actions.resetForm();
                  setIsSent(true);
                }}
                validationSchema={validationSchema}
              >
                <Form className="form-grid">
                  <ul className="flex flex-col pb-8 space-y-3 col-span-2 border-b border-dashed border-gray-700">
                    <li className="text-2xl font-medium sm:text-3xl text-blue-500">
                      Register for Test Rides
                    </li>
                    <li className="text-base text-gray-400 font-light">
                      Fill out the Form below to register for test rides
                    </li>
                  </ul>
                  <div className="form-field-container col-span-2  pt-4 pb-8 border-gray-700 border-b border-dashed">
                    <label htmlFor="cityName" className="form-label">
                      Choose your City
                    </label>
                    <Field
                      type="select"
                      as="select"
                      id="cityName"
                      name="cityName"
                      placeHolder="Choose"
                      className="form-input-field"
                    >
                      <option value="Choose">Choose</option>
                      {data.allPrismicTestRide.edges[0].node.data.test_ride_schedule.map(
                        (item) => (
                          <option value={item.city}>{item.city}</option>
                        )
                      )}
                    </Field>
                  </div>
                  <div className="form-field-container">
                    <label htmlFor="firstName" className="form-label">
                      First Name
                    </label>
                    <Field
                      type="text"
                      id="firstName"
                      name="firstName"
                      placeholder="First Name"
                      className="form-input-field"
                    />
                    <ErrorMessage name="firstName" component={formErrors} />
                  </div>
                  <div className="form-field-container">
                    <label htmlFor="lastName" className="form-label">
                      Last Name
                    </label>
                    <Field
                      type="text"
                      id="lastName"
                      name="lastName"
                      placeholder="Last Name"
                      className="form-input-field"
                    />
                    <ErrorMessage name="lastName" component={formErrors} />
                  </div>
                  <div className="form-field-container">
                    <label htmlFor="eMail" className="form-label">
                      E-Mail Address
                    </label>
                    <Field
                      type="text"
                      id="eMail"
                      name="eMail"
                      placeholder="E-Mail Address"
                      className="form-input-field"
                    />
                    <ErrorMessage name="eMail" component={formErrors} />
                  </div>
                  <div className="form-field-container">
                    <label htmlFor="mobileNumber" className="form-label">
                      Mobile Number
                    </label>
                    <ul className="flex flex-row">
                      <li className="flex flex-col items-center justify-center px-3 rounded-l bg-gray-800 shadow border-gray-700 border border-r-0 text-gray-400">
                        +91
                      </li>
                      <li className="flex-1">
                        <Field
                          type="text"
                          id="mobileNumber"
                          name="mobileNumber"
                          placeholder="91234567890"
                          className="form-input-field rounded-r rounded-l-none"
                        />
                      </li>
                    </ul>
                    <ErrorMessage name="mobileNumber" component={formErrors} />
                  </div>
                  <div className="form-field-container col-span-2 flex flex-row justify-end">
                    <button
                      type="submit"
                      className="contact-form-button border-r-0"
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              </Formik>
            ) : (
              <div className="flex flex-col items-center justify-center py-16 px-8">
                <p className=" text-2xl text-gray-400">
                  {" "}
                  Thank you For Submitting the Form,
                </p>
                <p className="pt-4 text-xl font-light text-gray-300">
                  Please check your Inbox! for further instructions.
                </p>
              </div>
            )}
          </div>
          <div className="bg-gray-900 p-2"></div>
        </section>
      </Layout>
    </>
  );
};

export default TestRides;
